/**
 * Vendors ´Cookie consent´
 */

import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../shared/utils/scroll';

import SelectorEngine from '../../../shared/dom/selector-engine';
import EventHandler from '../../../shared/dom/event-handler';

import {load} from '../../../shared/utils/load';

// -------
// Private
// -------

// ------
// Public
// ------

const init = () => {
	let inst = null;

	if (typeof ScCookie !== 'undefined') {
		inst = new ScCookie({
			on: {
				showPreferences: function () {
					lockBodyScrolling(this);
				},
				hidePreferences: function () {
					unlockBodyScrolling(this);
				},
				saveConsent: function (consent) {
					document.location.reload();
				},
				init: function (consent) {
					// Youtube?
					if (this.getConsent('youtube')) {
						const collection = SelectorEngine.find('[data-player-type="youtube"]');

						for (const element of collection) {
							EventHandler.trigger(element, 'disable.privacyMode');
						}
					}

					// Vimeo?
					if (this.getConsent('vimeo')) {
						const collection = SelectorEngine.find('[data-player-type="vimeo"]');

						for (const element of collection) {
							EventHandler.trigger(element, 'disable.privacyMode');
						}
					}

					// Googlemaps?
					if (this.getConsent('googlemaps')) {
						const collection = SelectorEngine.find('[data-gmap]');

						for (const element of collection) {
							EventHandler.trigger(element, 'disable.privacyMode');
						}
					}

					// Google Analytics?
					if (this.getConsent('googleanalytics')) {

						const script = document.createElement('script');

						script.src   = `https://www.googletagmanager.com/gtag/js?id=G-GZRY7MT164`;
						script.async = true;

						document.body.append(script);

						setTimeout(() => {
							window.dataLayer = window.dataLayer || [];
							function gtag(){dataLayer.push(arguments);}
							gtag('js', new Date());
							gtag('config', 'G-GZRY7MT164');
						}, 500);

						// load(`https://www.googletagmanager.com/gtag/js?id=G-GZRY7MT164`).then(() => {
						// 	window.dataLayer = window.dataLayer || [];
						// 	function gtag(){dataLayer.push(arguments);}
						// 	gtag('js', new Date());
						// 	gtag('config', 'G-GZRY7MT164');
						// });

					} else {
						document.cookie = "_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.provider-leipzig.de";
						document.cookie = "_ga_GZRY7MT164=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.provider-leipzig.de";
					}
				}
			}
		});
	}

	// Video: Aktivieren und abspielen
	// EventHandler.on(document.body, 'click', '[data-privacy-enable]', (ev) => {
	// 	console.log(Manipulator.getDataAttribute(ev.delegateTarget, 'privacy-enable'));
	// });

	// Custom-Trigger für Cookie-Einstellungen anzeigen.
	EventHandler.on(document.body, 'click', '[data-privacy-settings]', (event) => {
		event.stopPropagation();

		window.ifabCookieConsent.togglePreferences();
	});

	return window.ifabCookieConsent = inst;
};

// Export
export default init;
