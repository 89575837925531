/**
 * Sources / Default / Components / Custom  > Numbers
 *
 * @copyright 2024 i-fabrik GmbH
 * @author Tobias Garz
 */

import {extend} from '../../../../shared/utils';
import {
	isElement,
	isString
} from '../../../../shared/utils/is';

import SelectorEngine from '../../../../shared/dom/selector-engine';
import Data           from '../../../../shared/dom/data';
import Manipulator    from '../../../../shared/dom/manipulator';

// -------
// Private
// -------

const NAME     = 'number';
const DATA_KEY = `ifab.${NAME}`;
// const EVENT_KEY  = `.${DATA_KEY}`;
// const API_KEY    = '.data-api';


const renderElement = (element) => {
	const countEl = SelectorEngine.findOne('.number-item__counter', element);
	const counter = Manipulator.getDataAttribute(countEl, 'counter');

	if(!countEl && ! counter) {
		return;
	}

	const od = new Odometer({
		el: countEl,
		value: 0,
		theme: 'minimal',
		auto : true,
		// animation: 'count'
	});


	setTimeout(function() {
		od.update(parseInt(counter));
	}, 500);
};

// ------
// Public
// ------

const init = () => {
	const collection = SelectorEngine.find('.number-item');

	if(collection.length) {
		for(const element of collection) {
			renderElement(element);
		}
	}
};

// Export
export default {
	init: init
};
