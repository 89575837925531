/**
 * Shared: Components > Initialisierung
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import Button         from './button';
import Details        from './details';
import TabGroup       from './tab-group';
import Slider         from './slider';
import Form           from './form';
import Jumplist       from './jumplist';
import List           from './list';
import MediaGallery   from './media-gallery';
import Player         from './player';
import ProgressBar    from './progress-bar';
import ProgressRing   from './progress-ring';
import RevealPassword from './reveal-password';
import ScrollProgress from './scroll-progress';
import Tag            from './tag';

const index = () => {
	Button.init();
	Details.init();
	TabGroup.init();
	Slider.init();
	Form.init();
	Jumplist.init();
	List.init();
	MediaGallery.init();
	Player.init();
	ProgressBar.init();
	ProgressRing.init();
	RevealPassword.init();
	ScrollProgress.init();
	Tag.init();
};

export default index;
