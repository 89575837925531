/**
 * Shared: Components / Gmap > Google maps loader
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {load} from '../../utils/load';

// ------
// Public
// ------

/**
 * GoogleMapsApi
 * Class to load google maps api with api key
 * and global Callback to init map after resolution of promise.
 *
 * @exports {GoogleMapsApi}
 * @example MapApi = new GMapApiLoader();
 *          MapApi.load().then(() => {});
 */
class GoogleMapsLoader {
	/**
	 * Constructor
	 * @property {string} apiKey
	 * @property {string} callbackName
	 */
	constructor(key) {
		// api key for google maps
		this.apiKey = key;

		// Set global callback
		if (!window._GoogleMapsApi) {
			this.callbackName = '_GoogleMapsApi.loaded';

			window._GoogleMapsApi        = this;
			window._GoogleMapsApi.loaded = this.loaded.bind(this);
		}
	}

	/**
	 * Load
	 * Create script element with google maps
	 * api url, containing api key and callback for
	 * map init.
	 * @return {promise}
	 * @this {_GoogleMapsApi}
	 */
	load() {
		if (!this.promise) {
			this.promise = new Promise(resolve => {
				this.resolve = resolve;

				if (window.google === undefined) {
					// const script = document.createElement('script');
					//
					// script.src   = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&callback=${this.callbackName}`;
					// script.async = true;
					//
					// document.body.append(script);

					load(`https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&callback=${this.callbackName}`).then(() => {
						// Do nothing...
					});
				} else {
					this.resolve();
				}
			});
		}

		return this.promise;
	}

	/**
	 * Loaded
	 * Global callback for loaded/resolved map instance.
	 * @this {_GoogleMapsApi}
	 *
	 */
	loaded() {
		if (this.resolve) {
			this.resolve();
		}
	}
}

// Export
export default GoogleMapsLoader;
