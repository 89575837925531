/**
 * Navigation
 */

import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../shared/utils/scroll';

import SelectorEngine from '../../../shared/dom/selector-engine';
import Manipulator from '../../../shared/dom/manipulator';
import EventHandler from '../../../shared/dom/event-handler';

import Nav from '../../../shared/components/nav';
import Drawer from '../../../shared/components/drawer';

// -------
// Private
// -------

const NAME      = 'menu';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY    = '.data-api';

const pageMenu = SelectorEngine.findOne('#page-menu-drawer');

let mainNavInstance;

/**
 * Drawer (Navigationsoverlay)
 */
const setupDrawer = () => {
	const trigger = SelectorEngine.findOne('[aria-controls="page-menu-drawer"]');
	const drawer  = new Drawer(pageMenu, {
		closeButton: false,
		noHeader: 1,
		// onInit   : (event) => {
		// 	const componentCloseButton = SelectorEngine.findOne('.drawer__header > .drawer-close', event.target);
		//
		// 	if (componentCloseButton) {
		// 		componentCloseButton.remove();
		// 	}
		// },
		onShow   : function() {
			Manipulator.addClass(trigger, '-active');
		},
		onHidden : () => {
			Manipulator.removeClass(trigger, '-active');
		}
	});

	EventHandler.on(trigger, 'click.ifab.pageMenuDrawer', (event) => {
		event.preventDefault();
		event.stopPropagation();

		if (event.delegateTarget.classList.contains('-active')) {
			drawer.hide();
		} else {
			drawer.show();
		}
	});
};

/**
 * Initialisierung der eigentlichen Navigationsliste.
 */
const navigation = () => {
	const nav = SelectorEngine.findOne('#main-nav', pageMenu);

	if(!nav) {
		return;
	}

	Manipulator.addClass(nav, 'slide-menu');

	const slideNav = new SlideMenu(nav, {
		backLinkBefore : '<i class="far fa-arrow-left"></i>',
		submenuLinkAfter : '<i class="far fa-arrow-right"></i>',
	});

	EventHandler.on(nav, 'click', '.nav-link', (event) => {
		event.preventDefault();
		event.stopPropagation();

		const target = event.target;

		if(target.classList.contains('slide-menu__decorator')) {
			let navItem = SelectorEngine.parents(target, '.nav-item')[0];
			let subMenu = SelectorEngine.findOne('.nav-list', navItem);

			slideNav.navigateTo(subMenu);
		} else {
			window.location.href = event.delegateTarget.getAttribute('href');
		}
	});
};

// -------
// Public
// -------

const menu = () => {
	if (pageMenu) {
		setupDrawer();
		navigation();
	}
};

// Export
export default menu;
