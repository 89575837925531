/**
 * Components
 */

import GMap from '../../shared/components/gmap';
import Numbers from './custom/numbers';
import Table from './shared/table';

// ------
// Public
// ------

const index = () => {
	Table.makeScrollable();

	// const gMapAllowed = (typeof window.ifabCookieConsent !== 'undefined') ? window.ifabCookieConsent.getConsent('googlemaps') : true;
	//
	// if (gMapAllowed && typeof window.pageInstance.settings.maps.google.id) {
	GMap.init(null, {
		apiKey  : window.pageInstance.settings.maps.google.id,
		settings: {
			// Siehe google-maps.js -> DEFAULTS
			map: {
				zoom: 14
			}
		}
	});
	// }

	Numbers.init();
};

// Export
export default index;
