/**
 * Vendors ´DropdownJs´
 */

import {
	lockBodyScrolling,
	unlockBodyScrolling,
} from '../../../shared/utils/scroll';

import {needJquery} from '../../../shared/utils';

import SelectorEngine from '../../../shared/dom/selector-engine';
import EventHandler from '../../../shared/dom/event-handler';

// -------
// Private
// -------

const NAME      = 'dropdown-select';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;

// ------
// Public
// ------

const init = () => {
	let $container     = $(`[data-api="${NAME}"]`),
		getChosenItems = function(dropdown) {
			var $items = dropdown.find('.dropdown-selected');

			if($items.length > 0) {
				dropdown.addClass('-chosen');
			} else {
				dropdown.removeClass('-chosen');
			}
		};

	$container.each(function() {
		var $dropdown = $(this);

		$dropdown.dropdown({
			searchable:false,
			multipleMode:'label',
			placeholder: 'Test',
			init: function() {
				setTimeout(function() {
					getChosenItems($dropdown);
				}, 200);
			},
			choice: function(ev, el) {
				setTimeout(function() {
					getChosenItems($dropdown);

					// console.log(el.id);
					// console.log($dropdown.find('.dropdown-selected').eq(0).siblings());
					//
					// if(el.id === 0) {
					// 	$dropdown.find('.dropdown-selected').eq(0).nextAll().remove();
					// }
				}, 200);
			}
		});

		$dropdown.on('click', function() {
			setTimeout(function() {
				getChosenItems($dropdown);
			}, 200);
		});
	});
};

// Export
export default init;
