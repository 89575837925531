/**
 * Layout
 */

import {getRootVar} from '../../shared/utils';

import SelectorEngine from '../../shared/dom/selector-engine';

import header from './header';
import menu from './menu';
import footer from './footer';
import search from './search';

import Jumplist from '../../shared/components/jumplist';

/**
 * Sprungliste die einer Seite ´global´ hinzugefügt wurde.
 */
const pageJumplist = () => {
	const originalDocTitle = document.title;
	const originalDocUrl   = location.href;
	const pageJumplist     = SelectorEngine.findOne('#page-jumplist');

	if(pageJumplist) {
		const apiPageJumplist = Jumplist.init(pageJumplist, {
			windowMode: true, // Diese Jumplist ´behandelt´ das Window-Objekt!
			onNavigate: (event) => {
				//
				// ´Scroll top offset´ dem Zielbereich zuweisen.
				// Somit verschwindet er nicht visuell unter der Jumplist.
				//

				const sto = parseInt(getRootVar('global-scroll-top-offset') || 0, 10);
				const jlh = pageJumplist.offsetHeight;

				event.section.style.scrollMarginTop = `${jlh + sto}px`;

				//
				// Dokumenetentitel, -URL und Historieaktualisieren.
				//

				const newDocTitle = `${originalDocTitle} (${event.link.jumplistText})`;
				const historyUrl  = location.hash ? `${originalDocUrl.split('#')[0]}${event.link.jumplistHash}` : `${location.href.split('#')[0]}${event.link.jumplistHash}`;

				// Seitentitel aktualisieren.
				document.title = newDocTitle;

				// History aktualsieren.
				history.pushState({
					section: event.section.jumplistHash,
					title  : newDocTitle
				}, '', historyUrl);
			}
		});

		if (location.hash) {
			apiPageJumplist.activate(location.hash);
		}
	}
};

// ------
// Public
// ------

const index = () => {
	header();
	menu();
	footer();
	search();

	// Sprungliste (hinzugefügt einer Seite)
	pageJumplist();
};

// Export
export default index;
